import React, { useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import { NavBar, Footer } from './components';
import { Home, Dfuse, FAQ, Pricing } from './pages/Index';
import ProtectedRoute from "./auth/ProtectedRoute";
import { useHistory } from "react-router-dom";
import Page404 from "./pages/404"

function App() {
  const history = useHistory();
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    setIsUser(() => localStorage.getItem("isUser") === "true");
    // runDfuseCheck();
  }, []);

  // useEffect(()=>{
  //   if(isUser)
  //   {
  //     history.push("/dfuse")
  //   }    
  // }, [isUser]);

  return (
    <div className="App" style={{ fontFamily: "'Lato', sans-serif" }}>
      <NavBar />
      <div className="container">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/pricing" exact component={Pricing} />
          <ProtectedRoute path="/dfuse" component={Dfuse} />
          <ProtectedRoute path="/auth/login" component={Dfuse} />
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
