import React from "react";
import loadingImage from "../assets/loading.svg"
// const loadingImg =
//   "../assets/loading.svg";

const Loading = () => (
  <div className="spinner">
    <img src={loadingImage} alt="Loading..." />
  </div>
);

export default Loading;
