// src/components/authentication-button.js

import React, { useEffect } from "react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

import { useAuth0 } from "@auth0/auth0-react";
import localstorage from "../utils/localstorage";

const AuthenticationButton = () => {
  const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const setAccessTokenInLocalStorage = async () => {
    const accessToken = await getAccessTokenSilently();
    // let userObject = getUserProfileFromAuth0(user, accessToken);
    localstorage.storeUserOnLocalStorage(isAuthenticated, accessToken);
  }
  
  useEffect(() => {
    if (isAuthenticated){
      setAccessTokenInLocalStorage();
    }
  }, [isAuthenticated])

  return isAuthenticated ? <LogoutButton color={"#FFF"} type={1} /> : <LoginButton label={"Log In"} type={1}/>;
};

export default AuthenticationButton;
