import React, { FC, useEffect, useState } from "react";

class LocalStorage {

    static AuthSession = "authSession";
    static User = "user";

static storeUserOnLocalStorage = (isUser:any, accessToken: any) => {
    localStorage.setItem('isUser', isUser);
    localStorage.setItem('authSession', accessToken);
    // localStorage.setItem('token', isUser ? token : '');
    // localStorage.setItem('user', isUser ? userObject : '');
};

static removeUserFromLocalStorage = () => {
    localStorage.setItem('isUser', "false");
    localStorage.removeItem('authSession');
};

// static getAuthSession() {
//     return JSON.parse(localStorage.getItem(this.AuthSession));
// }

}

export default LocalStorage;