import React, { FC, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import { Button, Card, Tooltip, Typography } from "@material-ui/core";
import dfuseAPIs from "../services/dfuse";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { Grid } from "@material-ui/core";
import { Delete, FlashOn, FilterNone } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';
import Dashboard from "./Dashboard/Dashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // maxWidth: 1100,
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
  },
  generateButton:{
    background:"#d55f30",
    color: "#FFF",
    borderRadius:"18px",
    marginTop:"8px",
    "&:hover":{
      background:"#d55f30",
    }
  },
  removeButton:{
    [theme.breakpoints.down('xs')]: {
      display:"none"
    },
    [theme.breakpoints.up('sm')]: {
      width:"155px",
      backgroundColor: "#1830a6",
      color: "#FFF",
      borderRadius:"18px",
      "&:hover":{
        background:"#1830a6",
      }
    },
  },
  removeButtonGrid:{
    margin:"auto", 
    [theme.breakpoints.down('xs')]: {
      textAlign:"right",
    },
    [theme.breakpoints.up('sm')]: {
      textAlign:"right",
    }

  },
  iconButton:{
    [theme.breakpoints.down('xs')]: {
      backgroundColor: "#1830a6",
      color: "#FFF",
      "&:hover":{
        background:"#1830a6",
      }
    },
    [theme.breakpoints.up('sm')]: {
      display:"none"
    }
  },
  keyBox: {
    [theme.breakpoints.down('xs')]: {
     fontSize:"13px"
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:"15px"
    }
  }
}));

interface IKeyProps {
  api_key:string,
  api_key_id:string
}

const Dfuse = () => {
  const classes = useStyles();
  const {
    user
  } = useAuth0();


  const [isUser, setIsUser] = useState(false);
  // const [key, setKey] = useState("Empty");
  const [keys, setKeys] = useState([]);
  const [copiedText, setCopiedText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [reload, setReload] = useState(true);
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();


  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((accessToken) => {
        setIsUser(()=> localStorage.getItem("isUser") === "true");
        if (accessToken) {
          getAllKeys();
        }
      });
    }
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = "#f2f3fb";
  }, []);


  let allKeys:any = {};

  const getAllKeys = () => {
    dfuseAPIs.getAllAPIKeys().then(resp => {
      allKeys = resp.data;
      if (allKeys.data.api_keys.length > 0 ){
        setKeys(()=> allKeys.data.api_keys)
      }
      else {
        setKeys(() => [])
      }
    })
  }

  const handleGenerateKey = () => {
    dfuseAPIs.generateAnAPIKey().then(resp => {
      enqueueSnackbar("A new key was generated!",  { anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      }, variant:"success" });
      getAllKeys();
    }).catch(e => {
      
    })
  }

  const handleRemoveKey = (key:string) => {

    dfuseAPIs.removeAnAPIKey(key).then(resp => {
      if(key !== "Empty"){
        // setKey(()=> "Empty");
        enqueueSnackbar("Key removed successfully!",  { anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }, variant:"success" });
      }
    }).then(resp => getAllKeys())

  }

  const handleCopyToClipboard = (key:string) => {
    navigator.clipboard.writeText(key);
    setCopiedText(() => key);
  }

  return (
    isUser && user ?
    <div style={{padding: "8px"}}>
      <Card
        style={{
          width: "auto",
          height: "auto",
          padding: "16px",
          background: "#FFF",
          margin: "20px auto",
          maxWidth: "500px",
          borderRadius: "25px",
        }}
      >
      <div>
        <h2 style={{fontFamily: "Lato, sans-serif"}}>dfuse API Key Generator</h2>
      </div>


      {keys.map((key:IKeyProps, index) => {
        return <Grid container key={index}>
          <Grid item xs={10} sm={8}>
          <Tooltip
                  title={
                    copiedText === key.api_key
                      ? "Copied!"
                      : "Copy To Clipboard"
                  }
                  placement="top"
                >
                <Box
                    fontFamily="monospace"
                    fontWeight="400"
                    lineHeight="1.25"
                    display="inline-block"
                    width="90%"
                    margin=".5rem 0"
                    padding="16px"
                    textAlign="center"
                    border="0"
                    borderRadius="12px"

                    className={classes.keyBox}
                    style={{ 
                      backgroundColor:"#ffdabba3"
                    }}
                    onClick={()=> handleCopyToClipboard(key.api_key)}
                  >
                  {key.api_key}</Box>
              </Tooltip>
          </Grid>
          <Grid item xs={2} sm={4} className={classes.removeButtonGrid}>
            <Button variant="contained"
            startIcon={<Delete />}
            className={classes.removeButton}
            onClick={()=> handleRemoveKey(key.api_key)}>Remove Key</Button>

             <IconButton aria-label={`remove-key-${index}`} component="button" className={classes.iconButton} 
             onClick={()=> handleRemoveKey(key.api_key)}>
              <Delete fontSize="small"/>
            </IconButton>
          </Grid>
          </Grid>
      })
      }
      <Button variant="contained" 
        disabled={keys.length >= 10 ? true:false}
        startIcon={<FlashOn />}
        className={classes.generateButton}
        onClick={handleGenerateKey}
      >
        Generate Key
      </Button>
  
      </Card>

      <Dashboard/>

    </div>:''
  );
};

export default Dfuse;
