// @ts-nocheck
import React, { Fragment, useEffect, FC } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
// import { Hero } from "../components";
import Profile from "./Dfuse";
import { Typography, Grid } from "@material-ui/core";
import MeshNodes from '../components/MeshNodes';
import LoginButton from '../components/LoginButton';
import FAQ from "./FAQ/FAQ";
import Pricing from "./Pricing/Pricing";

interface UserProps {
  name: (key: string) => string,
  picture: (key: string) => string,
  email: (key: string) => string,
}
const useStyles = makeStyles((theme:Theme) => createStyles({
  heading: {
    
    [theme.breakpoints.down('xs')]: {
      fontSize:"32px",
      fontWeight:700,
      lineHeight: "40px",
      paddingBottom: "32px",
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:"60px",
      fontWeight:400,
      lineHeight: "60px",
      paddingBottom: "32px",
    },
  },
  introContainer:{
    height: "800px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    maxWidth: "1140px",
    margin: "0 auto",
    padding: "0 15px"
  },
  description:{
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize:"18px", 
      textAlign:"left"
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:"24px", 
      textAlign:"left"
    },
  },
  section: {
    backgroundColor: "#FBEFDF",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    color: "#FFF",
  },
  textBlock:{
    textAlign:"left"
  },
  gridTextBlock:{
    alignItems: "flex-start",
    [theme.breakpoints.down('xs')]: {
      padding: "56px 0px 56px 0px",
    },
    [theme.breakpoints.up('sm')]: {
      padding: "168px 0px 112px 0px",
    },
  },
}),
);


const Home:FC<UserProps>= () => {
  const classes = useStyles();
  const domain:any = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId:any = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience:any = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirectUri = `${domain}/dfuse`;
  const responseType:any = "code";
  const history = useHistory();
  const user:any= useAuth0().user;
  const { loginWithRedirect, loginWithPopup, getIdTokenClaims, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const isUser = localStorage.getItem('isUser');
  const handleClickSignin = async () => {
  
    await loginWithRedirect({
      client_id:clientId,
      response_type:responseType,
      redirectUri: redirectUri,
      audience:audience,
      scope:
      "openid email profile read:current_user update:current_user_identities",
    })

    return await getIdTokenClaims()
  }

  useEffect(() => {
    document.body.style.backgroundColor = "#000000";
  }, []);

  // const handleClickSignin = () => {
  //   // return <Auth0Provider></Auth0Provider>
  //   const response:any = fetch(`http://${domain}/authorize?audience=${audience}&responseType=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`, 
  //   {
  //     redirect:"manual"
  //   }
  //   )
  //   window.location.replace(response.url)
  // }


return (
  <>
    <section className={classes.section} style={{background: "linear-gradient(#000000, #210836)"}}>
          <div className={classes.introContainer} 
          style={{  position: "absolute", 
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            pointerEvents: "none"
          }}>
            <Grid container style={{ display:"flex", justifyContent:"space-between"}}>
              <Grid item xs={12} sm={12} md={6} className={classes.gridTextBlock}>
                <div className={classes.textBlock}>
                  <h1 className={classes.heading}>Stream and search blockchain data at a lightning fast speed</h1>
                  <p className={classes.description}>dfuse empowers developers with capabilities to build modern blockchain applications
with fast, fluid interfaces that deliver exceptional user experiences</p>
                </div>
                <div style={{pointerEvents: "all", textAlign:"left", paddingTop:"27px"}}>
                  <LoginButton label={"Log In to Get Started"}/>
                </div>
              </Grid>
            </Grid>
          </div>
          <MeshNodes style={{ position: "relative",}}  />
        </section>
        <section className={classes.section} style={{background: "linear-gradient(#210836, #210836)"}}>
        <Pricing/>
        </section>
        <section className={classes.section} style={{background: "linear-gradient(#210836, #000000)"}}>
          <FAQ/>
        </section>
        </>
)};

export default Home;
