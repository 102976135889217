// src/components/logout-button.js

import React, { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { localStorage } from '../utils/';

interface LogoutButtonProps {
  label?: string,
  type?: number,
  color?: string
}

const LogoutButton:FC<LogoutButtonProps> = ({label, type, color}) => {
  const { logout } = useAuth0();
  return (
    <Button
      variant={type==1 ? "text":"contained"}style={{ color:"#d65f30"}}
      onClick={() =>{ 
          logout({
            returnTo: window.location.origin,
          });
          localStorage.removeUserFromLocalStorage();
        }
      }
    >
      <PowerSettingsNew/>&nbsp;
      Log Out
    </Button>
  );
};

export default LogoutButton;
