import React, {FC} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from '@material-ui/core';
import buttonBG from '../assets/buttonBG.svg';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme:Theme) => createStyles({
  loginButton: {
    "&:hover": {
      transform: "scale(1.1)"
    }
  }


}))
interface LoginButtonProps {
  label: string,
  type?: number
}

const LoginButton:FC<LoginButtonProps> = ({label, type}) => {
  const classes = useStyles()
  const { loginWithRedirect, loginWithPopup, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const auth0Domain:any = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId:any = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience:any = process.env.REACT_APP_AUTH0_AUDIENCE;
  const domain: any = process.env.REACT_APP_DOMAIN;
  const responseType:any = "code";
  const redirectUri = `${domain}/dfuse`;

  const handleClickSignin = async () => {
  

    await loginWithRedirect({
      client_id: clientId,
      response_type: responseType,
      audience: audience,
      redirectUri:redirectUri,
      scope:
      "openid email profile read:current_user update:current_user_identities",
    });

    return await getIdTokenClaims();
  }
  return (
      <Button
      className={classes.loginButton}
      variant= {type==1 ? "text":"contained"}
      style={type==1 ? { backgroundColor:"#FFFFFF00", color:"#FFF"}:
      {
        backgroundImage: `url(${buttonBG})`, 
        backgroundSize: "cover",
        color:"#FFF",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "30px",
        paddingRight: "30px",
        borderRadius:"0px",
        fontWeight:"bold"
      }}
        onClick={ handleClickSignin }
      >
        {label}
      </Button>
  );
};

export default LoginButton;
