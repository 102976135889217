import Axios from './Axios';

const generateAnAPIKey = () => {
    const timeStamp = Date.now();
    let uuidString = timeStamp+"";
    // const uuid = uuidv4();
    const token = localStorage.getItem("authSession");
    const keyDescription = {
        "api_key_id": uuidString
    }
    return new Promise((resolve, reject) => {
        Axios.post(`/user/api_keys`, keyDescription)
        .then((resp) => {
            resolve(resp.data);
        })
        .catch((err) => {
            reject(err.response);
        });
    });
}

const removeAnAPIKey = (apiKey:string) => {
    // const request:any = {
    //     apiKey
    // }
    return new Promise((resolve, reject) => {
        Axios.delete(`/user/api_keys/${apiKey}`)
        .then((resp) => {
            resolve(resp.data);
        })
        .catch((err) => {
            reject(err.response);
        });
    });
}


interface dfuseKeys {
    data?: dfuse[]
}
interface dfuse {
  api?: string,
  key?: string
}

const getAllAPIKeys = () => {
    return Axios.get(`/user/api_keys`)
}
 


const getUsagStatsByDuration = (interval:string) => {
    return process.env.REACT_APP_ENV === "production" ? Axios.get(`user/usage?bucket=network&interval=${interval}`) : Axios.get(`user/usage/test?bucket=network&interval=${interval}`);
}

const getTotalUsage = () => {
    return process.env.REACT_APP_ENV === "production" ? Axios.get(`user/usage`) : Axios.get(`user/usage/test`);
}



export default { generateAnAPIKey, removeAnAPIKey, getAllAPIKeys, getUsagStatsByDuration, getTotalUsage }