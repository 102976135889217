import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { FC } from 'react';
import EOSNGold from '../assets/EOSNGold.png';
import EOSGem from '../assets/EOSGem.svg';
import { NavLink } from 'react-router-dom';


const useStyles = makeStyles((theme:Theme) => createStyles({
    footerTextGrid:{
        [theme.breakpoints.down('xs')]: {
            display:"flex", flexDirection:"column", justifyContent:"space-between", marginTop:"26px", color:"white"
          },
          [theme.breakpoints.up('sm')]: {
            display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"26px", color:"white"
          },
    },
    gemImage:{
        position: "relative",
        top: "5px"
    },
    hyperlink:{
        color:"white",
    },
    fonts:{
        fontSize:"12px", 
        fontFamily: "'Lato', sans-serif", 
        fontWeight:400,
        paddingBottom:"16px"
        // [theme.breakpoints.down('xs')]: {
        //     marginBottom: "16px"
        // }

    },
    textWithGem:{
        fontSize:"12px", 
        fontFamily: "'Lato', sans-serif", 
        fontWeight:400,
        paddingBottom:"16px",
        position: "relative",
        top: "-4px"
    }
}));

const Footer: FC = () => {
    const classes = useStyles();
    return (
        <div style={{backgroundColor:"#163A80", bottom: "0",
        position: "absolute",
        width: "100%"}}>
            <Grid container direction={"column"} className="intro__container" style={{ paddingTop:"56px", paddingBottom:"56px", display: "flex",
              justifyContent: "space-between",
              alignItems: "left"}}>
                <Grid item style={{width:"100px"}}>
                    <img src={EOSNGold} style={{height: "80px"}} alt="EOSN Gateway Footer"/>
                </Grid>
                <Grid item sm={6} className={classes.footerTextGrid}>
                    <Typography className={classes.fonts}>
                    Copyright Ⓒ 2021
                    </Typography>
                    <Typography className={classes.fonts}>
                        <NavLink to="/privacy-policy" exact className={classes.hyperlink}>Privacy policy</NavLink>
                    </Typography>
                    <Typography className={classes.textWithGem}>
                      Made with love for the EOS community &nbsp;&nbsp;<img src={EOSGem} alt="EOS Gem" className={classes.gemImage}/>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;