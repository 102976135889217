import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, TextField, MenuItem, Table, TableHead, TableRow, TableBody, TableCell } from "@material-ui/core";
import dfuseAPIs from "../../services/dfuse";

import { Box } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    // maxWidth: 1100,
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
  },
  card: {
    borderRadius: "25px", 
    padding:"30px 0px 0px 0px", 
    margin: "auto",
    [theme.breakpoints.down('xs')]: {
      fontSize: "14px",
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: "900px",
      fontSize: "18px",
      fontWeight: 600,
    }
  },
  tableBox: {
     height: 600,
    [theme.breakpoints.down('xs')]: {
      padding: "0px"
    },
    [theme.breakpoints.up('sm')]: {
      padding: "0px 16px"
    }
  },
  tableHeader: {
    textTransform: "uppercase",
  },
  tableTitle: {
    fontFamily: "Lato, sans-serif",
    fontSize: "24px",
    fontWeight: 600,
  },
  tableRow: {
    borderTop: "1px solid #E0E0E0",
    '&:hover': {
      backgroundColor: "#0000000a"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up('sm')]: {
      // fontSize: "18px",
    }
  },
  dropdown: {
    [theme.breakpoints.down('xs')]: {
      width: "275px"
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: "20px",
    }
  },
  dropdownDiv: {

    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
      paddingTop: "18px"
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: "right",
      top: "-47px",
      position: "relative",
    }
  }

}));


const usageDurations = [
  {
    value: 'day',
    label: 'Daily',
  },
  {
    value: 'week',
    label: 'Weekly',
  },
  {
    value: 'month',
    label: 'Monthly',
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const [usageData, setUsageData] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState<string>(usageDurations[1].value);

  const [tableData, setTableDaa] = useState({headers: [], records: []});

  useEffect(() => {
    getUsageStatsByDuration();
  }, [selectedDuration]);

  let stats: Array<any> = [];
  const getUsageStatsByDuration = () => {
    dfuseAPIs.getUsagStatsByDuration(selectedDuration).then(resp => {
      stats = resp.data.data;
      if (stats.length > 0) {
        setUsageData(() => resp.data.data);
        const tableHeaders = getReducedHeaderData(resp.data.data);
        const tableData = getReducedUsageData(tableHeaders, resp.data.data);
        setTableDaa({ headers: tableHeaders, records: tableData })
      }
      else {
        // setKeys(() => [])
      }
    })
  }

  const getReducedHeaderData = (usageData: any) => {
    let headerItems:any = [];
    let tempArray:any = [];
    tempArray.push("id");
    tempArray.push("date");
    usageData.reduce((accumulator:any, currentValue:any)=>{
      if (currentValue.hasOwnProperty('networks')){
        return currentValue.networks.forEach((network:any) => {
          tempArray.push(network.network)
        })
      }
    }, {})
    tempArray.push("total");
    headerItems = [...new Set(tempArray)];
    return headerItems;
  }

  const getReducedUsageData = (tHeaders: any, usageData: any) => {
    return usageData.reduce((accumulator:any, currentValue:any, index:number, originalArray: any)=>{
      let dataObject:any = new Object();
      dataObject.id=index+1;
      tHeaders.forEach((header:any) => {
        if (header === "id"){
          dataObject.id=index+1;
        }
        else if (header === "date"){
          dataObject[header] = new Date(currentValue.date).toUTCString().split(' ').slice(0, 4).join(' ').split(",").pop()?.trim();
        }
        else if (currentValue?.networks?.some((e:any) => e.network === header)) {
          currentValue.networks.forEach((network:any) => {
            if(network.network === header){
              dataObject[header] = network.docs_consumed;
            }})
        }
        else if (header === "total"){
          dataObject[header] = currentValue.total_docs_consumed;
        }
        else {
          dataObject[header] = 0;
        }
      })
      accumulator.push(dataObject);
      return accumulator
    }, [])
  }

  return (
    <>
      <Card className={classes.card}>
        <Typography className={classes.tableTitle}>Usage Stats By Network</Typography>
        <div className={classes.dropdownDiv}>
          <TextField
            id="outlined-select-timeframe"
            select
            label="Select"
            value={selectedDuration}
            onChange={(event): any => setSelectedDuration(() => (event.target.value))}
            helperText="Select timeframe"
            variant="outlined"
            className={classes.dropdown}
          >
            {usageDurations.map((duration) => (
              <MenuItem key={duration.value} value={duration.value}>
                {duration.label}
              </MenuItem>
            ))}
          </TextField>

        <Box className={classes.tableBox}>
          <DataGrid rows={tableData.records} components={{
              Toolbar: GridToolbar,
            }}
            columns={tableData.headers.map((header:any)=> {
              return {
                field: header,
                minWidth: 123,
                hide: header === 'id' || header === 'waxtest' || header === 'orestage',
                headerClassName: classes.tableHeader
            }
          })} />
        </Box>
          
        </div>
      </Card>
    </>)
}

export default Dashboard


