import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <RecoilRoot>
  <BrowserRouter>
    <Auth0ProviderWithHistory>
    <SnackbarProvider maxSnack={5} style={{backgroundColor:"#18AB6B", marginBottom:"20px", borderRadius: "25px 5px 5px 25px"}}>
      <App />
    </SnackbarProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
