// src/components/auth-nav.js

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import AuthenticationButton from "./AuthenticationButton";

const AuthNav = () => {

  // const { isAuthenticated } = useAuth0();


 return <div style={{ display: "flex", flexDirection:"row"}}>
    {/* {isAuthenticated ? <p style={{ color:"#1830a6", paddingRight: "20px" }}>Plan: Community</p> : ""} */}
    <AuthenticationButton />
  </div>
};

export default AuthNav;
