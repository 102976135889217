import { Grid, Card, Typography, Button, Link } from '@material-ui/core';
import React, { Fragment, useEffect, FC, useState } from "react";
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import { useHistory } from "react-router-dom";
import Plans from '../Plans/Plans';

const useStyles = makeStyles((theme: Theme) => createStyles({
    // heading: {
    //     "&:before":{
    //         content: "",
    //         position: "absolute",
    //         left: "50%",
    //         width: "60px",
    //         height: "2px",
    //         background: "#fff",
    //         bottom: "-25px",
    //         marginLeft: "-30px" 
    //     }
    // },

    container: {
        height: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        maxWidth: "1140px",
        margin: "0 auto",
        padding: "0 15px",
    },

    card: {
        padding: "24px",
        borderRadius: "25px",
        textAlign: "left",
        position: "relative",
        top: 0,
        transition: "top ease 0.3s",
        '&:hover': {
            cursor: 'pointer',
            top: "-10px",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
        },

    },

    title: {
        fontSize: "22px",
        textTransform: "uppercase",
        padding: "8px 0px",
        fontFamily: "'Lato', sans-serif"
    },

    description: {

    },

    money: {
        fontSize: "36px",
        fontFamily: "'Lato', sans-serif"

    },

    bulletPoint: {
        fontSize: "20px",
        display: "flex",
        fontFamily: "'Lato', sans-serif",
        // justifyContent:"left"

    },
    bulletPointIcon: {
        display: "flex",
        paddingRight: "10px",
        color: "#8629EC"
        // margin:"auto 0"
    },

    cardActionButton: {
        color: "white",
        backgroundColor: "#8629EC",
        width: "100%",
        paddingBottom: "8px"
    },

    telegramLink: {
        textDecoration: "none",
        color: "#1160a5",
        fontSize: "18px",
        "&:hover": {
            textDecoration: "underline"
        }

    }


}),
);


const Pricing = () => {
    const classes = useStyles();
    const history = useHistory();

    // const [cards, setCards] = useState([{
    //     cardTitle:"Community Edition",
    //     price:"Free",
    //     points:["Public EOSIO networks", "Hosted by EOS Nation", "Community support by public telegram", "Free; no credit card required", "Strict rate limit on requests (2 documents/second)", "Up to 2.5m documents/month", "30 days history"]
    // }, {
    //     cardTitle:"Business Edition",
    //     price:"$1500/month +",
    //     points:["Public EOSIO networks", "Hosted by EOS Nation", "Community support by public telegram", "Free; no credit card required", "Strict rate limit on requests (2 documents/second)", "Up to 2.5m documents/month", "30 days history"]
    // }, {
    //     cardTitle:"Enterprise Edition",
    //     price:"$5000/month +",
    //     points:["Public EOSIO networks", "Hosted by EOS Nation", "Community support by public telegram", "Free; no credit card required", "Strict rate limit on requests (2 documents/second)", "Up to 2.5m documents/month", "30 days history"]
    // }]);
    // const [cardElevation, setCardElevation] = useState(0);

    const handleContactUs = () => {
        // const url = `https://eosnation.io/contact-us/`;
        location.href = 'https://eosnation.io/contact-us/';
        // history.push("https://eosnation.io/contact-us/");
    }
    const handleSignup = () => {
        history.push("/dfuse");
    }
    return <section id="pricing">
        <div style={{ paddingTop: "56px", paddingBottom: "27px", fontFamily: "'Lato', sans-serif", textTransform: "uppercase", }}>
            <h2 style={{ fontWeight: 400 }}>Plans and Pricing</h2>
        </div>
        <Grid container className={classes.container} spacing={3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                    <Typography className={classes.title}>Community Edition</Typography>
                    <Typography className={classes.money}>Free</Typography>
                    <hr />
                    <div style={{ textAlign: "left", padding: "16px 0px" }}>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />Public EOSIO networks</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />Hosted by EOS Nation</Typography>
                        <Typography className={classes.bulletPoint} style={{ display: "inline" }}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E", display: "inline" }} />Community support by <Link className={classes.telegramLink}
                            href={"https://t.me/dfusece"}>public telegram</Link></Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />Free; no credit card required</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />No commitment</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />Strict rate limit on requests (2 documents/second)</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />Up to 2.5m documents/month</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#F2539E" }} />7 days history</Typography>
                    </div>
                    <Button variant="contained" className={classes.cardActionButton} style={{ backgroundColor: "#F2539E", borderRadius: "23px" }} onClick={handleSignup}>Signup Now</Button>

                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                    <Typography className={classes.title}>
                        Business Edition</Typography>
                    <Typography className={classes.money}>$1500/month +</Typography>
                    <hr />
                    <div style={{ textAlign: "left", padding: "16px 0px" }}>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />Public EOSIO networks</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />Hosted by EOS Nation</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />M-F 9-5 support
                            by email and private telegram</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />Starting at $1500/month	</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />Invoiced monthly</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />No strict rate limit on requests</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />3m documents/month included
                            + $10 / 1m documents (prepaid)
                            + $15 / 1m documents (on demand)</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} style={{ color: "#05D96B" }} />7 days history</Typography>
                    </div>
                    <Button variant="contained" className={classes.cardActionButton} style={{ backgroundColor: "#05D96B", borderRadius: "23px" }} onClick={handleContactUs}>Contact Us</Button>

                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                    <Typography className={classes.title}>
                        Enterprise Edition</Typography>
                    <Typography className={classes.money}>$5000/month +</Typography>
                    <hr />
                    <div style={{ textAlign: "left", padding: "16px 0px" }}>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />Public EOSIO networks or
                            Custom EOSIO network</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />Hosted by EOS Nation</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />24x7 support
                            by email and private telegram</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />Starting at $5000/month
                            + one time setup costs
                            + monthly compute costs</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />Invoiced monthly
                            Minimum 1 year commitment</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />No strict rate limit on requests</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />3m documents/month included
                            + $10 / 1m documents (prepaid)
                            + $15 / 1m documents (on demand)</Typography>
                        <Typography className={classes.bulletPoint}><CheckCircleIcon className={classes.bulletPointIcon} />Unlimited history</Typography>
                    </div>
                    <Button variant="contained" className={classes.cardActionButton} style={{ backgroundColor: "#8629EC", borderRadius: "23px" }} onClick={handleContactUs}>Contact Us</Button>
                </Card>
            </Grid>
        </Grid>
        <Plans />
    </section>

}

export default Pricing;