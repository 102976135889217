import { Grid, Card, Typography, Button, Link } from '@material-ui/core';
import React, { Fragment, useEffect, FC, useState } from "react";
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import {useHistory} from "react-router-dom";
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme:Theme) => createStyles({

    container:{
      height: "auto",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
      maxWidth: "1140px",
      margin: "0 auto",
      padding: "24px 24px 24px 0px",
      borderRadius:"25px",
    },
    featureBlock:{
        textAlign:"left",
        fontSize:"18px",
        fontFamily:"'Lato', sans-serif",
        padding:"8px 0px"
    },
    featureTitle:{
        fontSize:"22px",
        fontFamily:"'Lato', sans-serif",
        paddingBottom:"18px",
        textTransform:"uppercase",
        padding: "24px",
        // fontWeight:"400"
    },
    telegramLink:{
        textDecoration: "none",
        color: "#1160a5",
        fontSize: "18px",
        "&:hover":{
            textDecoration: "underline"
        }

    }
}))



const Plans = () => {
    const classes = useStyles();
    const [features, setFeatures] = useState([
        {
            featureName:"Products",
            community: false,
            business: false,
            enterprise: false
        },
        {
            featureName:"dfuse Search Real-time, historical and fork-aware search engine",
            community: true,
            business: true,
            enterprise: true
        },        {
            featureName:"dfuse State Historical state services",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"dfuse Lifecycle Transaction push guarantees & lifecycle",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"dfuse Custom branded block explorer",
            community: false,
            business: false,
            enterprise: true
        },
        {
            featureName:"dfuse Custom real-time feeds",
            community: false,
            business: false,
            enterprise: true
        },
        {
            featureName:"Support",
            community: false,
            business: false,
            enterprise: false
        },
        {
            featureName:"Community Support via {{TELEGRAM_LINK}}",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"Business Support Email & private chat M-F 9-5 support",
            community: false,
            business: true,
            enterprise: false
        },
        {
            featureName:"Enerprise Support Email & private chat 24/7/365 emergency support Guaranteed response time (custom)",
            community: false,
            business: false,
            enterprise: true
        },
        {
            featureName:"Networks",
            community: false,
            business: false,
            enterprise: false
        },
        {
            featureName:"EOS Mainnet",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"WAX Mainnet",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"ORE Mainnet",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"Kylin Testnet",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"Jungle Testnet (experimental)",
            community: true,
            business: true,
            enterprise: true
        },
        {
            featureName:"Custom EOSIO network",
            community: false,
            business: false,
            enterprise: true
        },

    ]);

    const renderTelegramLink = () => {
        return (
            <a target="_blank" className={classes.telegramLink} href={"https://t.me/dfusece"}>Telegram</a>
        )
      }
    
      const renderAnswer = (features: any) => {
        let answer = features;
        if (features.includes('{{TELEGRAM_LINK}}')) {
          return (
            <span>
              Community Support via {renderTelegramLink()}
            </span>
          );
        }
    
        return answer
      }

    return <div style={{  paddingBottom:"53px"}}>
        <div style={{ paddingTop: "56px", paddingBottom:"27px", fontFamily:"'Lato', sans-serif", textTransform: "uppercase", }}>
            <h2 style={{ fontWeight: 400}}>Pricing Details</h2>
        </div>
        <Card className={classes.container}>
            <Grid container >
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6} className={classes.featureTitle} style={{ textAlign:"left"}} />
                        <Grid item xs={2} className={classes.featureTitle} style={{backgroundColor:"#F2539E", color:"#FFF", borderRadius:"25px 25px 0px 0px"}}>
                        Community
                        </Grid>
                        <Grid item xs={2} className={classes.featureTitle} style={{backgroundColor:"#05D96B", color:"#FFF", borderRadius:"25px 25px 0px 0px"}}>
                        Business
                        </Grid>
                        <Grid item xs={2} className={classes.featureTitle} style={{backgroundColor:"#8629EC", color:"#FFF", borderRadius:"25px 25px 0px 0px"}}>
                        Enterprise
                        </Grid>
                    </Grid>
                </Grid>
                { features.map((item, index) => {
                    return(
                        <Grid item xs={12} key={`feature-${index}`}>
                            <Grid container>
                            <Grid item xs={6} className={classes.featureBlock} style={{paddingLeft: "24px",}}>
                                {item.featureName === "Products" || item.featureName === "Support" || item.featureName === "Networks"? <Typography 
                                style={{ fontWeight: 500, textTransform: "uppercase", fontSize: "18px", paddingTop:"18px"}}>{item.featureName}</Typography> : renderAnswer(item.featureName)}
                            </Grid>
                            <Grid item xs={2} >
                                {item.community ? <Check/> : ""}
                            </Grid>
                            <Grid item xs={2} >
                                {item.business ? <Check/> : ""}
                            </Grid>
                            <Grid item xs={2} >
                                {item.enterprise ? <Check/> : ""}
                            </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
                
            </Grid>
        </Card>
</div>


}

export default Plans;