import {useHistory} from "react-router-dom";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const MainNav = () => {
  const history = useHistory();
  const handleHomeButtonClick = () => {
    history.push('/')
  }
const { isAuthenticated } = useAuth0()
  
  return (<>{
    !isAuthenticated ? <div style={{display: "flex"}}>
      <div>
        <img style={{ width:"90px", height: "50px", cursor:"pointer"}} onClick={handleHomeButtonClick} src="https://eosnation.io/wp-content/uploads/2019/02/EOS-Nation-Logo-White.png"/>
      </div>
      <div style={{display:"flex", margin:"auto"}}>
        <img style={{ width: "122px", marginLeft: "24px", cursor:"pointer"}} onClick={handleHomeButtonClick} src="https://docs.dfuse.io/img/dfuse-logo.svg"/>
      </div>
    </div>: ""}</>
)};

export default MainNav;
