import React, { useState } from "react";
import EachFAQ from "../../components/FAQ";
import "./FAQ.css";

const FAQs = [
  {
    question: "What are documents?",
    answer: `A document is a single item in an API response. A response containing a list of items will count one document per item. A document can be streamed or paginated.`,
  },
  {
    question: "Can I try dfuse for free?",
    answer: "Yes. You can get started using the Community Edition on any of the public EOSIO networks. The Community Edition plan includes all the essentials for quickly and easily starting your blockchain project. Community Support via {{TELEGRAM_LINK}} is available.",
  },
  {
    question: "Can I build for free forever?",
    answer: `As long as you stay within the rate limits of the Community Edition plan, you can use it as long as you need to. If you need a higher rate limit, {{CONTACT_LINK}} to discuss which plan best fits your needs.`,
  },
  {
    question: "Do I need to enter my credit card to use dfuse for free?",
    answer: `You don't need to enter your credit card when using the dfuse Community Edition plan.`,
  },
  {
    question: "Will I be charged for all the API keys in my account?",
    answer: `We aggregate your usage for all API keys in use within your account and will be billed for the total account usage.`,
  },
  {
    question: "Where can I check my account usage?",
    answer: `You can check the account usage on the dashboard screen once you login.`,
  },
  {
    question: "What currency is used for payments?",
    answer: `Prices are in US Dollars.`,
  },
  {
    question: "What payment methods are accepted?",
    answer: `EOS, 
    International Wire Transfer,
    USDC stablecoin (on TRON or ETH), 
    USDT stablecoin (on TRON or ETH), 
    Visa,
    Mastercard,
    American Express,
    Google Pay`,
  },
  // {
  //   question: "What happens if my credit card is invalid/expires?",
  //   answer: `We will send you a notice that your payment method is invalid. We will contact you to gain updated information. Your account will be unaffected for up to 7 days after a failed charge while we work with you to resolve the issue.`,
  // },
  {
    question: "What languages is support available in?",
    answer: `Support is currently available in English, French and Chinese.`,
  },
  {
    question: "Will all API endpoints be subject to document usage?",
    answer: `Yes. Every endpoint will be subject to document count usage (including nodeos-compatible /v1/chain). Which means that you must provide an authentication token (through HTTP Authorization header) for every endpoint.`,
  },
  {
    question: " I can find some data older than 7 days. Can I use use them without paying for the Enterprise Edition plan?",
    answer: `dfuse currently contains more than 7 days of history, but we are not committing for that to continue in the future. You can use what is available while it is available. If you are relying on that, best to have an Enterprise Edition plan.`,
  },

];

const FAQ = () => {

  return (
    <section id="faq" className="faq-section">
      <div className="container">
        <div className="row" style={{ paddingBottom: "125px" }}>
          <div style={{ textAlign: "center" }}>
            <div className="faq-title text-center pb-3" style={{ paddingTop: "56px", paddingBottom: "56px" }}>
              <h2 style={{ fontWeight: 400, textTransform: "uppercase" }}>FAQ</h2>
            </div>
          </div>
          <div className="faq" id="accordion">
            {FAQs.map((faq, index) => (
              <EachFAQ
                key={`faq-${index}`}
                faq={faq}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
