import React from "react";

import MainNav from "./MainNav";
import AuthNav from "./AuthNav";
import { AppBar, Grid } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

const NavBar = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  return (
    <AppBar position="static" 
    style={ isAuthenticated ? {
      backgroundColor:"#163A8000", 
    } : location.pathname === "/pricing" || location.pathname === "/faq" ? {
      backgroundImage: "linear-gradient(to right, #d35e30, #641fe97a)"}: {
          backgroundColor:"#163A8000", 
          position: "absolute"}}
      elevation={0}>
      <Toolbar style={ isAuthenticated ? { display:"flex", justifyContent:"flex-end" } : { display:"flex", justifyContent:"space-between" }}>
          <MainNav />
      
          <AuthNav />
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;