import React, { FC, useState } from "react";
import { Typography } from '@material-ui/core';

interface FAQProps {
  faq: EachFAQ,
  index: number,
}

interface EachFAQ {
  question: string,
  answer: string,
}

const FAQ: FC<FAQProps> = ({ faq, index }) => {
  const [open, setOpen] = useState(false);

  const cardStyle = {
    display: open ? "flex" : "none",
    //opacity: show ? 0 : 1,
    transition: "all 1s ease-in",
  };

  const toggleFAQ = (index: number) => {
    setOpen(!open);
  };

  const renderContactLink = () => {
    return (
      <a href="https://eosnation.io/contact-us/">contact us</a>
    )
  }

  const renderTelegramLink = () => {
    return (
      <a href="https://t.me/dfusece" target="_blank">public telegram</a>
    )
  }

  const renderAnswer = (faq: any) => {
    let answer = faq.answer;
    if (faq.answer.includes('{{TELEGRAM_LINK}}')) {
      return (
        <span>
          Yes. You can get started using the Community Edition on any of the public EOSIO networks. The Community Edition plan includes all the essentials for quickly and easily starting your blockchain project. Community Support via {renderTelegramLink()} is available.
        </span>
      );
    }
    else if (faq.answer.includes('{{CONTACT_LINK}}')) {
      return (
        <span>
          As long as you stay within the rate limits of the Community Edition plan, you can use it as long as you need to. If you need a higher rate limit, {renderContactLink()} to discuss which plan best fits your needs.
        </span>
      )
    }

    if (faq.answer.includes('USDC')) {
      return (
        <>
          <span>
            We currently have the following payment methods available:<br />
            - EOS<br />
            - International Wire Transfer<br />
            - USDC stablecoin (on TRON or ETH)<br />
            - USDT stablecoin (on TRON or ETH)<br />
            - Credit Cards (Visa, Mastercard, AMEX)<br />
            - Google Pay<br />
          </span>
        </>
      )
    }

    return answer
  }

  return (
    <div className={"card"} key={index} onClick={() => toggleFAQ(index)}>
      <div className="card-header" id={`faqHeading-${index}`}>
        <Typography
          className="faq-title"
          data-toggle="collapse"
          data-target={`#faqCollapse-${index}`}
          data-aria-expanded="true"
          data-aria-controls={`faqCollapse-${index}`}
        >
          <span className="badge">{index + 1}</span>
          {faq.question}
        </Typography>
      </div>
      <div
        className="collapse"
        aria-labelledby={`faqHeading-${index}`}
        data-parent="#accordion"
        style={cardStyle}
      >
        <div className="card-body">
          <p>{renderAnswer(faq)}</p>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
